import React, { Suspense } from "react";
import { Route, Routes , useLocation } from "react-router-dom";
import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";
import "./App.css";
import LazyLoadingFallback from "./Components/LazyLoadingFallback/LazyLoadingFallback";
const Home = React.lazy(() => import('./Containers/Home/Home'));
const ClientEditor = React.lazy(() => import('./Containers/ClientEditor/ClientEditor'));
const PreviewEditor = React.lazy(() => import('./Containers/PreviewEditor/PreviewEditor'));
const App = (props) => {
  const location = useLocation();
  return (
    <div className="App">
      <TransitionGroup>
      <CSSTransition key={location.pathname} classNames="fade" timeout={450}>
      <Suspense fallback={<LazyLoadingFallback/>}>
      <Routes location={location}>
      <Route element={<Home />}  path="/" caseSensitive={false} />
      <Route element={<ClientEditor />} path="/ClientEditor" />
      <Route element={<PreviewEditor />} path="/PreviewEditor" />
      </Routes>
      </Suspense>
      </CSSTransition>
      </TransitionGroup>
    </div>
  );
};

export default App;
