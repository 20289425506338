import * as actionTypes from "../actionTypes";
const initialState = {
  Snippets: true,
  Autocompletion: true,
  LiveAutocompletion: true,
  FontSize: 16,
  ShowGutter: true,
  HighlightActiveLine: true,
  ShowPrintMargin: false,
  theme : 'one_dark'
};
const editorReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOGGLE_SNIPPETS:
      return {
        ...state,
        Snippets: !state.Snippets,
      };
    case actionTypes.AUTOCOMLETION:
      return {
        ...state,
        Autocompletion: !state.Autocompletion,
      };
    case actionTypes.LIVEAUTOCOMPLETION:
      return {
        ...state,
        LiveAutocompletion: !state.LiveAutocompletion,
      };
    case actionTypes.FONTSIZE:
      return {
        ...state,
        FontSize: action.fontsize,
      };
    case actionTypes.SHOWGUTTER:
      return {
        ...state,
        ShowGutter: !state.ShowGutter,
      };
    case actionTypes.HIGHLIGHTACTIVELINE:
      return {
        ...state,
        HighlightActiveLine: !state.HighlightActiveLine,
      };
    case actionTypes.SHOWPRINTMARGIN:
      return {
        ...state,
        ShowPrintMargin: !state.ShowPrintMargin,
      };
      case actionTypes.CHANGE_THEME:
        return {
          ...state,
          theme: action.theme,
        };
    default:
      return state;
  }
};
export default editorReducer;
